import { Link } from "react-router-dom";

function HeaderMenu() {
  return (
    <>
      <header className="sticky-header">
        <div className="header-nav">
          <div className="container-fluid container-1600">
            <div className="nav-container">
              <div className="site-logo">
                <Link to="/">
                  <img src={require('../assets/img/logo.png')} alt="Logo" style={{width: 200}} />
                </Link>
              </div>

              <div className="nav-menu d-lg-flex align-items-center">
                <div className="navbar-close">
                  <div className="cross-wrap">
                    <span></span>
                    <span></span>
                  </div>
                </div>

                <div className="menu-items">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li className="has-submemu">
                      <Link to="/about">About</Link>
                      <ul className="submenu">
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <a href="team.html">FAQ</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/portofolio">Portofolio</Link>
                    </li>
                    <li >
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                   
                  </ul>
                </div>

                <div className="nav-pushed-item"></div>
              </div>

              <div className="navbar-extra d-lg-block d-flex align-items-center">
                <div className="navbar-btn nav-push-item">
                  <a className="main-btn main-btn-3" href="https://api.whatsapp.com/send?phone=6282169542332&text=Saya%20ingin%20berkonsultasi%20dengan%20project%20saya">
                    Free Consultation
                  </a>
                </div>

                <div className="navbar-toggler">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderMenu;
