import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HeaderMenu, Footer } from "../components";
import { Nav, Tab } from "react-bootstrap";
function Services() {
  const [activeKey, setActiveKey] = useState("Website");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderMenu />
      <section class="breadcrumb-section bg-img-c" style={{backgroundImage: `url(${require("../assets/img/breadcrumb.jpg")})`}}>
        <div class="container">
          <div class="breadcrumb-text">
            <h1 class="page-title">Our Services</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Services</li>
            </ul>
          </div>
        </div>
        <div class="breadcrumb-shapes">
          <div class="one"></div>
          <div class="two"></div>
          <div class="three"></div>
        </div>
      </section>
      <section class="working-process-section">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10 order-lg-1 order-2">
              <div class="process-text">
                <div class="section-title left-border mb-30">
                  <span class="title-tag">Working Process</span>
                  <h2 class="title">How Dose We Works</h2>
                </div>
                <p>
                At Batam Web Media, we follow a systematic and efficient approach in delivering our services to clients. Here is an overview of how we work:
                </p>
                <div class="process-loop">
                  <div
                    class="single-process wow fadeInUp"
                    data-wow-duration="1500ms"
                    data-wow-delay="400ms"
                  >
                    <div class="icon">
                      <i class="fal fa-coffee"></i>
                      <span>01</span>
                    </div>
                    <div class="content">
                      <h4>Consultation and Requirements Gathering</h4>
                      <p>
                      The first step in our process is to have a thorough consultation with our clients. We believe in understanding your unique needs and objectives to provide tailored solutions.
                      </p>
                    </div>
                  </div>
                  <div
                    class="single-process wow fadeInUp"
                    data-wow-duration="1500ms"
                    data-wow-delay="600ms"
                  >
                    <div class="icon">
                      <i class="fal fa-coffee"></i>
                      <span>02</span>
                    </div>
                    <div class="content">
                      <h4>Development and Collaboration</h4>
                      <p>
                      After gathering all the necessary information, our skilled team of developers and designers will begin the development process. We follow an iterative and collaborative approach, keeping you involved throughout the development cycle.
                      </p>
                    </div>
                  </div>
                  <div
                    class="single-process wow fadeInUp"
                    data-wow-duration="1500ms"
                    data-wow-delay="800ms"
                  >
                    <div class="icon">
                      <i class="fal fa-coffee"></i>
                      <span>03</span>
                    </div>
                    <div class="content">
                      <h4>Quality Assurance and Delivery</h4>
                      <p>
                      Once the development phase is complete, we shift our focus to quality assurance and testing. Our dedicated QA team rigorously tests the project to ensure it meets the highest standards of functionality, usability, and security.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="working-circle"></div>
      </section>

      <Tab.Container
        activeKey={activeKey}
        onSelect={(key) => setActiveKey(key)}
      >
        <section class="service-section grey-bg service-line-shape section-gap">
          <div class="container">
            <div class="section-title text-center both-border mb-50">
              <span class="title-tag">Most Features</span>
              <h2 class="title">
                We Provide Most Exclusive <br /> Service For Business
              </h2>
            </div>
            <div class="service-tab">
              <Nav
                variant="tabs"
                className="justify-content-center"
                defaultActiveKey="Website"
              >
                <Nav.Item>
                  <Nav.Link eventKey="Website">Website</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Application">Application</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Cryptocurrency">Cryptocurrency</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="Website">
                <div class="row service-boxes justify-content-center">
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/web_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <Link to="/service-website-landing-page">Landing Page</Link>
                      </h3>
                      <Link to="/service-website-landing-page" class="service-link"><i class="fal fa-long-arrow-right"></i></Link>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/web_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <Link to="/service-web-application">Web Application</Link>
                      </h3>
                      <Link to="/service-web-application" class="service-link"><i class="fal fa-long-arrow-right"></i></Link>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/web_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <Link to="/service-website-news">News Website</Link>
                      </h3>
                      <Link to="/service-website-news" class="service-link"><i class="fal fa-long-arrow-right"></i></Link>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/web_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <Link to="/service-website-ecommerce">E-commerce</Link>
                      </h3>
                      <Link to="/service-website-ecommerce" class="service-link"><i class="fal fa-long-arrow-right"></i></Link>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Application">
                <div class="row service-boxes justify-content-center">
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/mobile_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">Crypto Wallet</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/mobile_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">News App</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/mobile_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">Web3 App</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/mobile_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">Custom App</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Cryptocurrency">
                <div class="row service-boxes justify-content-center">
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/blockchain_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">Smart Contract</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/blockchain_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">Dapps Developer</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/blockchain_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">Exchange Builder</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/blockchain_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">Altcoin Developer</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/blockchain_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">ICO Development</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/blockchain_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">NFT Marketplace</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/blockchain_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">Fork Ethereum</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-10">
                    <div class="service-box-three border-0">
                      <div class="icon">
                        <img src={require("../assets/img/services/blockchain_icon.png")} alt="Icon" />
                      </div>
                      <h3>
                        <a href="#">Dex Developer</a>
                      </h3>
                      <a href="#" class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
          <div class="line-one">
            <img src={require("../assets/img/lines/12.png")} alt="Logo" />
          </div>
          <div class="line-two">
            <img src={require("../assets/img/lines/11.png")} alt="Logo" />
          </div>
        </section>
      </Tab.Container>
      <Footer />
    </>
  );
}

export default Services;
