function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-widget">
            <div className="row">
              <div className="col-lg-4 col-sm-5 order-1">
                <div className="widget site-info-widget">
                  <div className="footer-logo">
                    <img src={require('../assets/img/logo.png')} alt="" />
                  </div>
                  <p>
                  We are a team of web designers and web developers based in Indonesia. We've done many projects. Adjusting with the recent change of habit among global internet users
                  </p>
                  <ul className="social-links">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-behance"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-dribbble"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-sm-7 order-2">
                <div className="widget newsletter-widget">
                  <h4 className="widget-title">Subscribe Our Newsletters</h4>
                  <div className="newsletter-form">
                    <form action="#">
                      <input type="email" placeholder="Enter Your Email" />
                      <button type="submit" className="main-btn">
                        Subscribe Now
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 order-3">
                <div className="widget nav-widget">
                  <h4 className="widget-title">Quick Links</h4>
                  <ul>
                    <li>
                      <a href="#">Company History</a>
                    </li>
                    <li>
                      <a href="#">Latest News & Blog</a>
                    </li>
                    <li>
                      <a href="#">Popular Services</a>
                    </li>
                    <li>
                      <a href="#">Business & Consulting</a>
                    </li>
                    <li>
                      <a href="#">Financial Planning</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 order-lg-4 order-5">
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <div className="widget nav-widget">
                      <h4 className="widget-title">Company</h4>
                      <ul>
                        <li>
                          <a href="#">About Comapny</a>
                        </li>
                        <li>
                          <a href="#">World Wide Clients</a>
                        </li>
                        <li>
                          <a href="#">Happy People’s</a>
                        </li>
                        <li>
                          <a href="#">Winning Awards</a>
                        </li>
                        <li>
                          <a href="#">Company Statics</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="widget contact-widget">
                      <h4 className="widget-title">Contact Us</h4>
                      <p>Untrammelled & nothing preven our being able</p>
                      <ul className="contact-infos">
                        <li>
                          <a href="tel:+0123456789">
                            <i className="far fa-phone"></i>
                            +62 821-6954-2332
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <p className="copyright-text">
              <span>
                <a href="#">Batam Web Media</a>. 2015 -2023
              </span>
              <span>All Right Reserved</span>
            </p>
            <a href="#" className="back-to-top">
              <i className="far fa-angle-up"></i>
            </a>
          </div>
        </div>
        <img src={require('../assets/img/lines/01.png')} alt="line-shape" className="line-one" />
        <img src={require('../assets/img/lines/02.png')} alt="line-shape" className="line-two" />
      </footer>
    </>
  );
}

export default Footer;
