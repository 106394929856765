import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { HeaderMenu, Footer } from "../components";
import {
  banner1,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  video1,
} from "../utils/staticImages";
function HomeScreen() {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: true,
    fade: false,
    dots: true,
    swipe: true,
    adaptiveHeight: true,
    // nextArrow: '<button className="slick-arrow next-arrow"><i className="fal fa-long-arrow-right"></i></button>',
    // prevArrow: '<button className="slick-arrow prev-arrow"><i className="fal fa-long-arrow-left"></i></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  const settingsClient = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: true,
    fade: false,
    dots: false,
    swipe: true,
    adaptiveHeight: true,
    // nextArrow: '<button className="slick-arrow next-arrow"><i className="fal fa-long-arrow-right"></i></button>',
    // prevArrow: '<button className="slick-arrow prev-arrow"><i className="fal fa-long-arrow-left"></i></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderMenu />
      <section className="banner-section">
        <Slider className="banner-slider" {...settings}>
          <div
            className="single-banner"
            style={{backgroundImage: `url(${require("../assets/img/banner/01.jpg")})`}}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-10">
                  <div className="banner-content">
                    <span
                      className="promo-text"
                      data-animation="fadeInDown"
                      data-delay="0.8s"
                    >
                      business & consulting
                    </span>
                    <h1 data-animation="fadeInUp" data-delay="1s">
                      Making Difference <br /> Growth Your Business <br />In
                      Digital era
                    </h1>
                    <ul className="btn-wrap">
                      <li data-animation="fadeInLeft" data-delay="1.2s">
                        <a href="https://api.whatsapp.com/send?phone=6282169542332&text=Saya%20ingin%20berkonsultasi%20dengan%20project%20saya" className="main-btn main-btn-4">
                          Get Started Now
                        </a>
                      </li>
                      <li data-animation="fadeInRight" data-delay="1.4s">
                        <Link to="/services" className="main-btn main-btn-2">Our Services</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner-shapes">
              <div className="one"></div>
              <div className="two"></div>
              <div className="three"></div>
              <div className="four"></div>
            </div>
          </div>
        </Slider>
      </section>
      <section className="about-section about-illustration-img section-gap">
        <div className="container">
          <div className="illustration-img">
            <img
              src={require("../assets/img/illustration/01.png")}
              alt="Image"
            />
          </div>
          <div className="row no-gutters justify-content-lg-end justify-content-center">
            <div className="col-lg-6 col-md-10">
              <div className="about-text">
                <div className="section-title left-border mb-40">
                  <span className="title-tag">About Us</span>
                  <h2 className="title">
                    Batam Web Media - IT Developer - Marketing Agency
                  </h2>
                </div>
                <p className="mb-25">
                We have complete and easy solutions for even your most difficult IT management problems, from keeping your business safe, to ensuring high availability, to making your users happy. We’re bringing IT together so you don’t have to.
                </p>
                <p>
                  At the end of the day, Digitalize is about your needs. As you prepare for the IT management challenges ahead, we'll lead the way with new solutions, contextual integrations, and other advances that can only come from a company singularly dedicated to customer.
                </p>
                <ul className="about-list">
                  <li>
                    <i className="far fa-check"></i> Company Solutions
                  </li>
                  <li>
                    <i className="far fa-check"></i> Application Development
                  </li>
                  <li>
                    <i className="far fa-check"></i> Network Infrastructure
                  </li>
                  <li>
                    <i className="far fa-check"></i> Social Media Marketer
                  </li>
                </ul>
                <Link to="/about" className="main-btn">Learn More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-section shape-style-one section-gap grey-bg">
        <div className="container">
          <div className="section-title text-center both-border mb-30">
            <span className="title-tag">Comany Services</span>
            <h2 className="title">
              We Provide Most Exclusive <br /> Service For Business
            </h2>
          </div>
          <div className="row service-boxes justify-content-center">
            <div
              className="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInLeft"
              data-wow-duration="1500ms"
              data-wow-delay="400ms"
            >
              <div className="service-box text-center">
                <div className="icon">
                  <img src={require("../assets/img/icons/01.png")} alt="Icon" />
                </div>
                <h3>
                  <a href="#">Creative Idea</a>
                </h3>
                <p>Dont waste your time to handle all the implementation of the idea on your project, let us do that :)</p>
                <a href="#" className="service-link">
                  <i className="fal fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="600ms"
            >
              <div className="service-box text-center">
                <div className="icon">
                  <img src={require("../assets/img/icons/02.png")} alt="Icon" />
                </div>
                <h3>
                  <a href="#">Business Strategy</a>
                </h3>
                <p>We give the best solution for your marketing plan, so you dont bother about any tactless in your business.</p>
                <a href="#" className="service-link">
                  <i className="fal fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInRight"
              data-wow-duration="1500ms"
              data-wow-delay="800ms"
            >
              <div className="service-box text-center">
                <div className="icon">
                  <img src={require("../assets/img/icons/03.png")} alt="Icon" />
                </div>
                <h3>
                  <a href="#">Relationship Buildup</a>
                </h3>
                <p>Relationship is the key of the well project running, we have many option relationship that suitable for your business.</p>
                <a href="#" className="service-link">
                  <i className="fal fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="dots-line">
          <img src={require("../assets/img/lines/07.png")} alt="Image" />
        </div>
      </section>
      <section
        className="video-section bg-img-c section-gap"
        // style={{ backgroundImage: `url(${video1})` }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-7 col-lg-8 col-md-10 order-2 order-md-1">
              <div className="video-text">
                <div className="section-title left-border mb-30">
                  <span className="title-tag">Watch Videos</span>
                  <h2 className="title">
                    Exclusive Video Presentation <br /> About Us
                  </h2>
                </div>
                <p>
                  This is our company profile video that you can watch about what we doing and what we do for digitalization era.
                </p>
                <a href="#" className="main-btn">
                  Interested with us?
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-lg-4 col-md-2 order-1 order-md-2">
              <div
                className="video-btn text-md-center wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              >
                <a href="#" className="play-btn popup-video">
                  <img src={require("../assets/img/icons/play.svg").default} alt="" />
                  <i className="fas fa-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="line-shape">
          <img src={require("../assets/img/lines/08.png")} alt="Line" />
        </div>
      </section>

      <section className="feature-section section-gap">
        <div className="container">
          <div className="section-title text-center both-border mb-50">
            <span className="title-tag"> Our Core Features </span>
            <h2 className="title">
              We Are Specialist For Many <br /> Business Solutions
            </h2>
          </div>
          <div className="feature-boxes row justify-content-center">
            <div className="col-lg-4 col-md-6 col-10 col-tiny-12">
              <div className="feature-box">
                <div
                  className="feature-bg bg-img-c"
                  style={{ backgroundImage: `url(https://img.freepik.com/free-vector/gradient-nft-concept-illustrated_52683-61452.jpg?w=1380&t=st=1687269105~exp=1687269705~hmac=886b60fc9128fb98c70ad8863e67249a8ec4aa352202ac049784034ffda390e5)` }}
                ></div>
                <div className="feature-desc">
                  <a href="#" className="feature-link">
                    <i className="fal fa-long-arrow-right"></i>
                  </a>
                  <h4>Cryptocurrency Developer</h4>
                  <p>Bitcoin, Ethereum developer.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-10 col-tiny-12">
              <div className="feature-box">
                <div
                  className="feature-bg bg-img-c"
                  style={{ backgroundImage: `url(https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg?w=1380&t=st=1687268886~exp=1687269486~hmac=9bace851b93c51e17e2e545629e864ed8613be2c04b913561e042ad184840c3e)` }}
                ></div>
                <div className="feature-desc">
                  <a href="#" className="feature-link">
                    <i className="fal fa-long-arrow-right"></i>
                  </a>
                  <h4>Mobile Development</h4>
                  <p>Android and iOS development.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-10 col-tiny-12">
              <div className="feature-box">
                <div
                  className="feature-bg bg-img-c"
                  style={{ backgroundImage: `url(https://img.freepik.com/free-vector/hand-drawn-web-developers_23-2148819604.jpg?w=1380&t=st=1687268913~exp=1687269513~hmac=6b2250d6c8a6dc03907c030f4f33f4f3043ad1bc50365c9c761129eb72ff1347)` }}
                ></div>
                <div className="feature-desc">
                  <a href="#" className="feature-link">
                    <i className="fal fa-long-arrow-right"></i>
                  </a>
                  <h4>Web Developer</h4>
                  <p>Web application development.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-10 col-tiny-12">
              <div className="feature-box">
                <div
                  className="feature-bg bg-img-c"
                  style={{ backgroundImage: `url(https://img.freepik.com/free-vector/cloud-services-isometric-composition-with-big-cloud-computing-infrastructure-elements-connected-with-dashed-lines-vector-illustration_1284-30495.jpg?w=900&t=st=1687268949~exp=1687269549~hmac=834c35262049759456398abfa7520a4b6a14afacb4964df5f6e468854f35535c)` }}
                ></div>
                <div className="feature-desc">
                  <a href="#" className="feature-link">
                    <i className="fal fa-long-arrow-right"></i>
                  </a>
                  <h4>Network Infrastructure</h4>
                  <p>Relating container server installation.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-10 col-tiny-12">
              <div className="feature-box">
                <div
                  className="feature-bg bg-img-c"
                  style={{ backgroundImage: `url(https://img.freepik.com/free-vector/3d-social-media-icons-background_52683-28863.jpg?w=1380&t=st=1687268970~exp=1687269570~hmac=1fb02ae23c1e4d4500243d422a59e652a472ae2ec94c5fc6745ecdca60d62c2a)` }}
                ></div>
                <div className="feature-desc">
                  <a href="#" className="feature-link">
                    <i className="fal fa-long-arrow-right"></i>
                  </a>
                  <h4>Social Media Marekting</h4>
                  <p>Targeted marketing on social media.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-10 col-tiny-12">
              <div className="feature-box">
                <div
                  className="feature-bg bg-img-c"
                  style={{ backgroundImage: `url(https://img.freepik.com/free-photo/creative-company-professional-digital-engineer-modeling-3d-mesh-while-improving-geometry-asset-creator-optimising-polygon-count-while-using-cad-create-advanced-virtual-sketch_482257-42940.jpg?w=1380&t=st=1687269061~exp=1687269661~hmac=4eb2e2fb447d8b279b233de2d8dd52ce050c58d7d0b64d94a0c9b8900d2e384b)` }}
                ></div>
                <div className="feature-desc">
                  <a href="#" className="feature-link">
                    <i className="fal fa-long-arrow-right"></i>
                  </a>
                  <h4>Design Modelling</h4>
                  <p>Video, Game, Character, and Company profile</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wcu-section box-style">
        <div className="container">
          <div className="wcu-inner">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6">
                <div
                  className="wcu-image text-center text-lg-left wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="400ms"
                >
                  <img
                    src={require("../assets/img/illustration/02.png")}
                    alt="Image"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="wcu-text">
                  <div className="section-title left-border mb-40">
                    <span className="title-tag">What We Do</span>
                    <h2 className="title">
                      Why Choose Batam <br /> Web Media
                    </h2>
                  </div>
                  <p>
                  Batam Web Media is a leading IT company that excels in developing a wide range of digital solutions for various industries. Whether you're looking for app development, web design, crypto projects, or marketing strategies, Batam Web Media offers comprehensive services tailored to meet your specific needs. Here's why you should consider choosing Batam Web Media for your next project:
                  </p>
                  <ul className="wcu-list">
                    <li>
                      <i className="far fa-check-circle"></i> Expertise in Multiple Industries</li>
                    <li>
                      <i className="far fa-check-circle"></i> Full Range of Services
                    </li>
                    <li>
                      <i className="far fa-check-circle"></i> Customized Solutions
                    </li>
                    <li>
                      <i className="far fa-check-circle"></i> Skilled and Experienced Team
                    </li>
                    <li>
                      <i className="far fa-check-circle"></i> Commitment to Quality and Timeliness
                    </li>
                  </ul>
                  <a href="https://api.whatsapp.com/send?phone=6282169542332&text=Saya%20ingin%20berkonsultasi%20dengan%20project%20saya" hrefLang="_blank" className="main-btn main-btn-4">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <img
              src={require("../assets/img/lines/03.png")}
              alt="shape"
              className="line-shape-one"
            />
            <img
              src={require("../assets/img/lines/04.png")}
              alt="shape"
              className="line-shape-two"
            />
          </div>
        </div>
      </section>
      <section className="fact-section grey-bg">
        <div className="container">
          <div className="fact-boxes row justify-content-between align-items-center">
            <div className="col-lg-3 col-6">
              <div className="fact-box text-center mb-40">
                <div className="icon">
                  <i className="flaticon-graphic"></i>
                </div>
                <h2 className="counter">3568</h2>
                <p className="title">Project Completed</p>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="fact-box text-center mb-40">
                <div className="icon">
                  <i className="flaticon-plan"></i>
                </div>
                <h2 className="counter">7859</h2>
                <p className="title">Satisfied Clients</p>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="fact-box text-center mb-40">
                <div className="icon">
                  <i className="flaticon-target-1"></i>
                </div>
                <h2 className="counter">6352</h2>
                <p className="title">International Awards</p>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="fact-box text-center mb-40">
                <div className="icon">
                  <i className="flaticon-teamwork"></i>
                </div>
                <h2 className="counter">7856</h2>
                <p className="title">Expert Team Members</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="client-slider section-gap line-bottom">
            <div>
              <Slider
                className="row align-items-center justify-content-between"
                {...settingsClient}
              >
                <div className="col">
                  <a href="#" className="client-img d-block text-center">
                    <img src={require("../assets/img/clients/01.png")} alt="" />
                  </a>
                </div>
                <div className="col">
                  <a href="#" className="client-img d-block text-center">
                    <img src={require("../assets/img/clients/02.png")} alt="" />
                  </a>
                </div>
                <div className="col">
                  <a href="#" className="client-img d-block text-center">
                    <img src={require("../assets/img/clients/03.png")} alt="" />
                  </a>
                </div>
                <div className="col">
                  <a href="#" className="client-img d-block text-center">
                    <img src={require("../assets/img/clients/04.png")} alt="" />
                  </a>
                </div>
                <div className="col">
                  <a href="#" className="client-img d-block text-center">
                    <img src={require("../assets/img/clients/05.png")} alt="" />
                  </a>
                </div>
                <div className="col">
                  <a href="#" className="client-img d-block text-center">
                    <img src={require("../assets/img/clients/01.png")} alt="" />
                  </a>
                </div>
                <div className="col">
                  <a href="#" className="client-img d-block text-center">
                    <img src={require("../assets/img/clients/02.png")} alt="" />
                  </a>
                </div>
                <div className="col">
                  <a href="#" className="client-img d-block text-center">
                    <img src={require("../assets/img/clients/03.png")} alt="" />
                  </a>
                </div>
                <div className="col">
                  <a href="#" className="client-img d-block text-center">
                    <img src={require("../assets/img/clients/04.png")} alt="" />
                  </a>
                </div>
                <div className="col">
                  <a href="#" className="client-img d-block text-center">
                    <img src={require("../assets/img/clients/05.png")} alt="" />
                  </a>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default HomeScreen;
