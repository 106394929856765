import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { HeaderMenu, Footer } from "../../components";

function ServicesWebLanding() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderMenu />
      <section class="breadcrumb-section bg-img-c">
        <div class="container">
          <div class="breadcrumb-text">
            <h1 class="page-title">Web Landing</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>Web Landing</li>
            </ul>
          </div>
        </div>
        <div class="breadcrumb-shapes">
          <div class="one"></div>
          <div class="two"></div>
          <div class="three"></div>
        </div>
      </section>
      <section class="service-details section-gap">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="service-details-content">
                     <div class="main-thumb mb-40">
                        <img src={require('../../assets/img/services/services-landingpage.jpg')} alt="image" />
                     </div>
                     <h2 class="title">Elevate Your Online Presence with Professional Web Landing Development Services</h2>
                     <p>
                     In today's digital era, having a strong online presence is crucial for businesses to thrive and succeed. A well-designed and strategically developed web landing page can be a powerful tool in capturing the attention of potential customers, driving conversions, and achieving business objectives. At Batam Web Media, we offer expert web landing development services to help businesses create engaging and high-converting landing pages that make a lasting impression.
                     </p>
                     <br />
                     <h4 class="sub-title">Captivating Design:</h4>
                     <p>Our team of skilled web developers understands the importance of visually appealing design. We combine creativity and user-centric design principles to create captivating web landing pages that align with your brand identity. By using the latest design trends and techniques, we ensure that your landing page stands out from the competition and leaves a positive impression on visitors.</p>

                     <br />
                     <h4 class="sub-title">Conversion-Driven Approach:</h4>
                     <p>We believe that an effective web landing page should be more than just visually appealing – it should be optimized for conversions. Our experienced developers leverage industry best practices and data-driven insights to create landing pages that drive action. We strategically place compelling calls-to-action (CTAs), optimize page load speed, and create intuitive user experiences to maximize conversions and achieve your business goals.</p>

                     <br />
                     <h4 class="sub-title">Responsive and Mobile-Friendly:</h4>
                     <p>In today's mobile-dominated landscape, it is essential that your web landing page is fully responsive and optimized for mobile devices. Our development team ensures that your landing page is seamlessly accessible across various screen sizes and devices. By adopting a mobile-first approach, we guarantee that your message is effectively delivered to your audience, regardless of the device they use.</p>
                     
                     <br />
                     <h4 class="sub-title">Customization and Flexibility:</h4>
                     <p>We understand that each business has unique needs and objectives. That's why our web landing development services are highly customizable and flexible. Our team collaborates closely with you to understand your specific requirements and tailor the landing page to reflect your brand's personality. From choosing color schemes to incorporating brand elements, we ensure that your landing page is a true representation of your business.</p>
                     
                     <br />
                     <h4 class="sub-title">Integration and Tracking:</h4>
                     <p>To help you gain valuable insights and make data-driven decisions, we offer integration with various analytics and tracking tools. Whether it's integrating with Google Analytics to track visitor behavior or setting up conversion tracking pixels, we ensure that your landing page is optimized for monitoring and analyzing key metrics. This enables you to measure the success of your campaigns and make informed optimizations for better performance.</p>
                     
                     <br />
                     <h4 class="sub-title">Conclusion:</h4>
                     <p>With our professional web landing development services, you can elevate your online presence and drive meaningful results for your business. At Batam Web Media, we combine captivating design, conversion-driven strategies, responsiveness, and customization to create landing pages that engage and convert visitors. By partnering with us, you can take your online marketing efforts to the next level and unlock the full potential of your business. Contact us today to discuss your web landing development needs and embark on a journey towards digital success.</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="cta-aection">
         <div class="container">
            <div class="cta-wrap bg-img-c" style={{backgroundImage: `url(${require("../../assets/img/lines/16.png")})`}}>
               <div class="row justify-content-center">
                  <div class="col-lg-8">
                     <div class="cta-content text-center">
                        <div class="section-title both-border mb-30">
                           <span class="title-tag">Get A Quote</span>
                           <h2 class="title">Feel Any Project For Business Consulting Get Started Us</h2>
                        </div>
                        <Link to="/contact" class="main-btn main-btn-3">Contact Us</Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <br />
      <Footer />
    </>
  );
}

export default ServicesWebLanding;
