import { createBrowserRouter } from "react-router-dom";
import { HomeScreen, ContactScreen, AboutScreen, ServicesScreen, NotFoundScreen, PortofolioScreen } from "../screens";
import { WebLandingScreen, WebAppScreen, WebNewsScreen, WebEcommerceScreen } from "../screens/services/index";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/about",
    element: <AboutScreen />,
  },
  {
    path: "/services",
    element: <ServicesScreen />,
  },
  {
    path: "/contact",
    element: <ContactScreen />,
  },
  {
    path: "/portofolio",
    element: <PortofolioScreen />,
  },
  {
    path: "/service-website-landing-page",
    element: <WebLandingScreen />,
  },
  {
    path: "/service-web-application",
    element: <WebAppScreen />,
  },
  {
    path: "/service-website-news",
    element: <WebNewsScreen />,
  },
  {
    path: "/service-website-ecommerce",
    element: <WebEcommerceScreen />,
  },
  
  {
    path: "*",
    exact: true,
    element: <NotFoundScreen />,
  },
]);
