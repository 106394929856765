import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'

import "./assets/css/animate.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/magnific-popup.css";
// import "./assets/css/slick.css";
import "./assets/css/jquery-ui.min.css";
import "./assets/css/style.css";

// import "./assets/js/bootstrap.min.js";
// import "./assets/js/popper.min.js";
// import "./assets/js/slick.min.js";
// import "./assets/js/isotope.pkgd.min.js";
// import "./assets/js/jquery.magnific-popup.min.js";
// import "./assets/js/jquery.inview.min.js";
// import "./assets/js/jquery.countTo.js";
// import "./assets/js/jquery.easypiechart.min.js";
// import "./assets/js/jquery-ui.min.js";
// import "./assets/js/wow.min.js";
import "./assets/js/main.js";
const tagManagerArgs = {
  gtmId: 'G-04WP20JP1Z'
}
TagManager.initialize(tagManagerArgs)
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
