import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { HeaderMenu } from "../components";

function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderMenu />
      <section class="breadcrumb-section bg-img-c" style={{backgroundImage: `url(${require("../assets/img/breadcrumb.jpg")})`}}>
        <div className="container">
          <div className="breadcrumb-text">
            <h1 className="page-title">404</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
        <div className="breadcrumb-shapes">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </section>
    </>
  );
}

export default NotFound;
