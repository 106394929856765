import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { HeaderMenu, Footer } from "../components";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderMenu />
      <section class="breadcrumb-section bg-img-c" style={{backgroundImage: `url(${require("../assets/img/breadcrumb.jpg")})`}}>
        <div className="container">
          <div className="breadcrumb-text">
            <h1 className="page-title">Contact Us</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>
        <div className="breadcrumb-shapes">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </section>
      <section className="contact-section contact-page section-gap-top">
        <div className="container">
          <div className="contact-info">
            <div className="row justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="illustration-img text-center">
                  <img
                    src={require("../assets/img/logo512.png")}
                    alt="Logo"
                    style={{ width: 200 }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                <div className="contact-info-content">
                  <div className="section-title left-border mb-40">
                    <span className="title-tag">Get In Touch</span>
                    <h2 className="title">
                      Need Any Help For Business & Consulting
                    </h2>
                  </div>
                  <ul>
                    <li className="phone">
                      <a href="tel:+6282169542332">
                        <i className="far fa-phone"></i>+62 821 6954 2332
                      </a>
                    </li>
                    <li>
                      <i className="far fa-envelope-open"></i>
                      contact@batamwebmedia.com
                    </li>
                    <li>
                      <i className="far fa-map-marker-alt"></i>Batam, Indonesia
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-form grey-bg">
            <div className="row no-gutters justify-content-center">
              <div className="col-10">
                <div className="section-title text-center mb-40">
                  <h2 className="title">Don’t Hesited To Contact Us</h2>
                </div>
                <form action="#">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="input-group mb-30">
                        <input type="text" placeholder="Your Full Name" />
                        <span className="icon">
                          <i className="far fa-user-circle"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input-group mb-30">
                        <input type="email" placeholder="Your Email Address" />
                        <span className="icon">
                          <i className="far fa-envelope-open"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input-group mb-30">
                        <input type="text" placeholder="Your Phone" />
                        <span className="icon">
                          <i className="far fa-phone"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group textarea mb-30">
                        <textarea placeholder="Write Message"></textarea>
                        <span className="icon">
                          <i className="far fa-pencil"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button type="submit" className="main-btn">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid container-1600">
          <div className="contact-map"></div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
