import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { HeaderMenu, Footer } from "../components";

function About() {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <>
      <HeaderMenu />
      <section class="breadcrumb-section bg-img-c" style={{backgroundImage: `url(${require("../assets/img/breadcrumb.jpg")})`}}>
         <div class="container">
            <div class="breadcrumb-text">
               <h1 class="page-title">About Us</h1>
               <ul>
                  <li><Link to="/">Home</Link></li>
                  <li>About Us</li>
               </ul>
            </div>
         </div>
         <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
         </div>
      </section>
      <section class="job-details-section section-gap">
         <div class="container">
            <div class="row">
               <div class="col-lg-8">
                  <div class="job-details-wrapper">
                     <div class="job-details">
                        <h3 class="title">About Us</h3>
                        <div class="info">
                           <strong class="label">About</strong>
                           <div class="desc">Founded in 2015, Batam Web Media is a leading IT services company specializing in website development, application development, web application solutions, and cryptocurrency development. With a team of highly skilled professionals, we are committed to providing innovative and tailored solutions to meet the diverse needs of our clients.</div>
                        </div>
                        <div class="info">
                           <strong class="label">Website Development</strong>
                           <div class="desc">At Batam Web Media, we understand the importance of a strong online presence in today's digital landscape. Our expert website development team excels in creating visually appealing and user-friendly websites that effectively communicate your brand message. Whether you need a simple informational website or a complex e-commerce platform, we leverage the latest technologies and industry best practices to deliver outstanding results.</div>
                        </div>
                        <div class="info">
                           <strong class="label">Application Development</strong>
                           <div class="desc">We also offer comprehensive application development services to cater to your specific business requirements. Our experienced developers specialize in crafting robust, scalable, and feature-rich applications that streamline your internal processes, enhance customer engagement, and boost operational efficiency. From mobile applications to desktop software, we are dedicated to delivering high-quality solutions that drive tangible results for your organization.</div>
                        </div>
                        <div class="info">
                           <strong class="label">Web Application Solutions:</strong>
                           <div class="desc">Batam Web Media recognizes the growing demand for web applications that provide advanced functionalities and seamless user experiences. Our team excels in developing custom web applications tailored to your unique business needs. Whether you require a content management system, customer relationship management software, or an online booking system, our experts have the expertise to design and develop powerful web applications that enhance your business operations and improve user engagement.</div>
                        </div>
                        <div class="info">
                           <strong class="label">Cryptocurrency Development:</strong>
                           <div class="desc">As the world embraces the potential of blockchain technology and cryptocurrencies, Batam Web Media stays at the forefront of this exciting industry. Our team of skilled developers has extensive knowledge and experience in cryptocurrency development. Whether you need assistance with creating your own cryptocurrency, building decentralized applications (DApps), or implementing smart contracts, we provide comprehensive solutions to help you leverage the benefits of this transformative technology.</div>
                        </div>
                        <div class="info">
                           <strong class="label">Our Approach:</strong>
                           <div class="desc">At Batam Web Media, we pride ourselves on delivering exceptional services that prioritize client satisfaction. We adopt a client-centric approach, collaborating closely with you to understand your objectives and develop customized solutions that align with your vision. We ensure transparent communication throughout the project lifecycle, providing regular updates and seeking your feedback to ensure we meet your expectations.</div>
                        </div>
                        <div class="info">
                           <strong class="label">Conclusion</strong>
                           <div class="desc">With our established presence in the industry since 2015, Batam Web Media has earned a reputation for excellence in IT services. Whether you need a stunning website, powerful applications, web application solutions, or cryptocurrency development expertise, we are here to meet your needs. Partner with us to harness the power of technology and achieve your business goals with confidence.</div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4">
                  <div class="sidebar">
                     <div class="widget newsletter-widget">
                        <div class="newsletter-content">
                           <h5>SUBSCRIBE</h5>
                           <h4>SUBSCRIBE FOR NEWSLETTER</h4>
                           <form>
                              <div class="form_group">
                                 <input type="email" placeholder="Email" name="email" required />
                                 <button class="newsletter-btn">Subscribe</button>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
